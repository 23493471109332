import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "../components/SEO"
import FullscreenCarousel from '../components/fullscreenCarousel'

import { Container, Row, Col } from 'react-bootstrap'
import * as indexStyles from '../styles/index.module.scss'


const IndexPage = ({data}) => {

  let Cards = 
    [
      ["Crafted by skilled artisans", "We partner with excellent artisans from many different fields of art"],
      ["Custom-made", "Each product can be made to customer's wishes by adding unique designs."],
      ["Interior design", "Created to enrich indoor living spaces"],
      ["Unique design", "Designed by Latuss"],
      ["Quality materials", "Every product is made exclusively with high quality, durable materials."],
      ["Handcrafted", "Each product is individually crafted by skilled artisans."],
    ].map( (item, index) => 
          <Col 
            lg={4} md={6} 
            className={indexStyles.rowItem} 
            data-sal="slide-up" 
            data-sal-delay={`${ (index % 3) * 100}`}
            key={index}>
              <p>{ item[0] }</p>
              <p>{ item[1] }</p>
          </Col>
    )

  let CardsRow = () => 
    <div className={indexStyles.cards}>
      <Container>
        <Row>
          { Cards }
        </Row>
      </Container>
    </div>


  return <>
    <Seo />
    <FullscreenCarousel />
    <div className={indexStyles.row1}>
      <Container>
        <Row xs={{cols: 1}} md={{cols: 2}}>
          <Col>
            <div className={indexStyles.textContainer}>
              <h1>
                <span>LATUSS</span><br />
                <span>LARIX</span><br />
                <span>MEDIUM </span>
                <span> LAMP</span><br />
              </h1>
              <div style={{display: "flex", justifyContent: "center"}}>
                <Link 
                  to="/lamps/" 
                  className={indexStyles.buttonLink}
                  tabIndex={-1}
                >
                  <button>EXPLORE</button>
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={{order: 1}} sm={{order: 2}}>
            <div className={indexStyles.imageWrapper} >
              <GatsbyImage image={data.lamps.childImageSharp.gatsbyImageData} alt="All lamps" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    {/* Second row -- ogledalo -- TODO */}
    {/* Third row */}
    <CardsRow />
    {/* Fourth row - link to contact */}
    <div className={indexStyles.contact}>
      <Container data-sal="slide-up" data-sal-delay={`0`} >
        <p>
          FOR INQUIRIES AND ORDERS
        </p>
        <hr />
        <Link 
          to="/contact/" 
          className={indexStyles.contactButton} 
          tabIndex={-1}
        >
          <button>Contact us</button>
        </Link>
      </Container>
    </div>
  </>
}


export default IndexPage;


export const query = graphql`{
  lamps: file(relativePath: {eq: "index/globina_hd.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 425
        quality: 90
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: "#c9ae9f"}
      )
    }
  }
}
`