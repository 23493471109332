import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

import { Carousel } from 'react-bootstrap'
import { headerImage, carouselItem } from '../styles/index.module.scss'


const FullscreenCarousel = () => {

    const data = useStaticQuery(graphql`
        query CarouselQuery {
          headerImage1: file(relativePath: {eq: "carousel/top1.png"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 75, 
                layout: FULL_WIDTH
                breakpoints: [1366, 1920]
              )
            }
          }
          headerImage2: file(relativePath: {eq: "carousel/top2.png"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 75, 
                layout: FULL_WIDTH
                breakpoints: [1366, 1920]
              )
            }
          }
          headerImage3: file(relativePath: {eq: "carousel/top3.png"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 75, 
                layout: FULL_WIDTH
                breakpoints: [1366, 1920]
              )
            }
          }
          headerImage1Vertical: file(relativePath: {eq: "carousel/top1.png"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 75, 
                layout: FULL_WIDTH
                aspectRatio: 0.5625
                transformOptions: {cropFocus: NORTH}
                breakpoints: [450, 600, 750, 1080]
              )
            }
          }
          headerImage2Vertical: file(relativePath: {eq: "carousel/top2-vertical.png"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 75, 
                layout: FULL_WIDTH
                breakpoints: [450, 600, 750, 1080]
              )
            }
          }
          headerImage3Vertical: file(relativePath: {eq: "carousel/top3-vertical.png"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 75, 
                layout: FULL_WIDTH
                breakpoints: [450, 600, 750, 1080]
              )
            }
          }
        }
    `)


    // load vertical (9/16) image on portrait devices
    const images = [
      withArtDirection(getImage(data.headerImage1), [
          {
              media: "(max-width: 1080px)",
              image: getImage(data.headerImage1Vertical)
          },
      ]),
      withArtDirection(getImage(data.headerImage2), [
          {
              media: "(max-width: 1080px)",
              image: getImage(data.headerImage2Vertical)
          },
      ]),
      withArtDirection(getImage(data.headerImage3), [
          {
              media: "(max-width: 1080px)",
              image: getImage(data.headerImage3Vertical)
          },
      ]),
    ]
    

    return (
      <div className={headerImage}>
          <Carousel
            fade 
            controls={false}>
            <Carousel.Item>
            <GatsbyImage
                image={images[0]}
                alt={"slika"}
                className={carouselItem}
                objectPosition="50% 0%" />
            </Carousel.Item>
            <Carousel.Item>
            <GatsbyImage
                image={images[1]}
                alt={"slika"}
                className={carouselItem}
                objectPosition="50% 0%" />
            </Carousel.Item>
            <Carousel.Item>
            <GatsbyImage
                image={images[2]}
                alt={"slika"}
                className={carouselItem}
                objectPosition="50% 0%" />
            </Carousel.Item>
          </Carousel>
      </div>
    )
}


export default FullscreenCarousel